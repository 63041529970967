import React from "react";
import "./ButtonWhatsapp.css";

const ButtonWhatsapp = (props) => {
 
const { telefonoWhatsapp , auto} = props.info;
const numero =  '2614546178';
const datos = auto ? auto : false; 
const texto = `para%20asegurar%20el%20siguiente%20vehículo%20${datos.marca},${datos.version},%20modelo%20${datos.anio}`;

  return (
    <div className="buttonwhatsapp">
      <div className="btnCenter">
        <div className="d-none d-sm-none d-md-block text-end">
          <a
            href={`https://web.whatsapp.com/send/?phone=%2B549${numero}&text=Buen+día+quiero+información%20${texto ? texto : ''}&app_absent=0`}
            target="_blank"
          >
            <img
              src="https://cronogram.com.ar/assets/logo-wasap.png"
              className="shadow"
              style={{ borderRadius: "50px 50px" }}
              width={80}
              height={80}
            />
          </a>
        </div>
        <div className="d-sm-block d-md-none text-end">
          <a
            href={`https://api.whatsapp.com/send/?phone=%2B549${numero}&text=Buen+día+quiero+información%20${texto ? texto : ''}&app_absent=0`}
            target="_blank"
          >
            <img
              src="https://cronogram.com.ar/assets/logo-wasap.png"
              className="shadow"
              style={{ borderRadius: "50px 50px" }}
              width={60}
              height={60}
            />
          </a>
        </div>
      </div>
    </div>
  );
};



export default ButtonWhatsapp;
