import { RiMessage2Line } from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import { LiaPhoneVolumeSolid } from "react-icons/lia";
import { IoMailOutline } from "react-icons/io5";
import ConsultaModalChat from "../ConsultaModalChat/ConsultaModalChat";
import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./SeccionEstamos.css";

const SeccionEstamos = (props) => {
  const [showModal, setShowModal] = useState(false);

  const info = props.info;
  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <div className="fondo-donde">
      <div className="col-12 text-center pt-5 mb-5">
        <div className="container text-center ">
          <h1 className="text-app">ESTAMOS CERCA</h1>
         
          <div className="box-estamos">
            <h1>Mirá nuestra sucursal en el mapa</h1>
            <NavLink to={info && "mailto:" + info.email} target="_blank">
              <div className="boton-segumas shadow">
                <strong>CLIC ACÁ</strong>
              </div>
            </NavLink>
          </div>
        </div>
      </div>
      <ConsultaModalChat show={showModal} onHide={handleCloseModal} />
    </div>
  );
};

export default SeccionEstamos;
