"use client";
import React from 'react';
import './SeccionAutoDescuento.css';
import PropTypes from 'prop-types';

const SeccionAutoDescuento = ({}) => {
	return (
		<div className='seccionautodescuento'>
 			<div className='container d-none d-sm-none d-md-block'>
				<div className='texto-promocion'>
				<span style={{fontSize:'56px'}}>¿TODAÍA NO TENÉS SEGURO?</span>
				<span style={{fontSize:'41px'}}>CONTRATALO HASTA CON UN</span>
				<span style={{fontSize:'136px'}}><strong>-10%</strong></span>
				<span style={{fontSize:'56px'}}><strong>DE DESCUENTO</strong></span>
				<span style={{fontSize:'41px', fontStyle:'italic'}}>CONTACTATE CON TU PRODUCTOR DE SEGUROS</span>
				<span style={{fontSize:'23px'}}><strong>BASES DE LA PROMOCIÓN</strong></span>
				</div>
			</div>
			<div className='container d-sm-block d-md-none'>
				<div className='texto-promocion '>
				<span style={{fontSize:'35px'}}>¿TODAÍA NO TENÉS SEGURO?</span>
				<span style={{fontSize:'35px'}}>CONTRATALO HASTA CON UN</span>
				<span style={{fontSize:'50px'}}><strong>-10%</strong></span>
				<span style={{fontSize:'35px'}}><strong>DE DESCUENTO</strong></span>
				<span style={{fontSize:'25px', fontStyle:'italic'}}>CONTACTATE CON TU PRODUCTOR DE SEGUROS</span>
				<span style={{fontSize:'23px'}}><strong>BASES DE LA PROMOCIÓN</strong></span>
				</div>		
			</div>
 		</div>
	);
};

SeccionAutoDescuento.propTypes = {};

export default SeccionAutoDescuento;