import { useState} from "react";
import "./NavHeader.css";
import * as BsIcons from "react-icons/bs";
import logo from "../../assets/Marca Segumas_Original.png";
import { NavLink } from "react-router-dom";

function NavHeader (props){
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };


  const cardContent = (
    <div style={{width:'100%'}}>SEGUROS</div>
  );

  const hoverContent = (
    <div className="shadow" style={{width:'92%',position:'absolute',background:'#fff'}}>
      <NavLink exact to="/seguro-auto">
        <div className="text-center  menu-item">Autos</div>
      </NavLink>
      <NavLink exact to="/seguro-hogar">
        <div className="text-center  menu-item">Hogar</div>
      </NavLink>
    </div>
  );
  const [isHovered2, setIsHovered2] = useState(false);
  const handleMouseEnter2 = () => {
    setIsHovered2(true);
  };

  const handleMouseLeave2 = () => {
    setIsHovered2(false);
  };


  const cardContent2 = (
    <div style={{width:'100%'}}>CONTACTO</div>
  );

  const hoverContent2 = (
    <div className="shadow" style={{width:'92%',position:'absolute',background:'#fff'}}>
      <NavLink exact to="/quienes-somos">
        <div className="text-center  menu-item">Quienes somos</div>
      </NavLink>
      <NavLink exact to="/contactenos">
        <div className="text-center  menu-item">Canales de comunicación</div>
      </NavLink>
      <NavLink exact to="/donde-estamos">
        <div className="text-center  menu-item">Donde estamos</div>
      </NavLink>
      <NavLink exact to="/login">
        <div className="text-center  menu-item">Acceso Productor</div>
      </NavLink>
    </div>
  );



  return (
    <div className="navheader mb-4">
      <div className="nav-img mb-3 pt-3">
        <NavLink exact to="/">
          <img src={logo} alt="Marca" className="img-fluid"  width={200}/>
        </NavLink>        
      </div>
      <div className="d-none d-sm-none d-md-block">
        <div className="">
          <div className="row" style={{background:'#fff'}}>       
                <div
                  onMouseEnter={handleMouseEnter}
                  onMouseLeave={handleMouseLeave}
                  className="col-2"
                  style={{position:'relative'}}
                >
                  <div className="text-center" width='100'>
                  {cardContent}
                  {isHovered && hoverContent}
                  </div>
      
            </div>

          
            <div className="col-2">
            <NavLink exact to="/quienes-somos">
                INSTITUCIONAL
              </NavLink>
            </div>
             <div
                  onMouseEnter={handleMouseEnter2}
                  onMouseLeave={handleMouseLeave2}
                  className="col-2"
                  style={{position:'relative'}}
                >
                  <div className="text-center" width='100'>
                  {cardContent2}
                  {isHovered2 && hoverContent2}   
            </div>
            </div>
            <div className="col-2"></div>
            <div className="col-1">
              <a href={props.info ? props.info.instagram_url : '#'} >
              <BsIcons.BsInstagram />
              </a>
             
            </div>
            <div className="col-1">
            <a href={props.info ? props.info.facebook_url: '#'}>
              <BsIcons.BsFacebook />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavHeader;
