import React from "react";
import NavHeader from "../../components/NavHeader/NavHeader";
import Footer from "../../components/Footer/Footer";
import SeccionAtencion from "../../components/SeccionAtencion/SeccionAtencion";
import NavBarSeg from "../../components/NavBarSeg/NavBarSeg";

function Donde(props) {
  const mapa = (
    <iframe
      src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d6700.5666412444725!2d-68.85093910820875!3d-32.89067667592222!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x967e093ec45179bf%3A0x205a78f6d20efa3a!2sMendoza%2C%20Capital%2C%20Mendoza!5e0!3m2!1ses-419!2sar!4v1696559218013!5m2!1ses-419!2sar"
      width="100%"
      height="300"
      allowfullscreen=""
      loading="lazy"
      
    ></iframe>
  );
  return (
    <div>
       <NavBarSeg className="d-none d-sm-none d-md-block"/>
      <div className="container " style={{paddingTop:'80px'}}>
      
        <div className="container pt-5"><br/>
        <div className="text-center">
        <span className="h1 text-app">¿Dónde estamos?</span>
        </div>
          <div className="text-start">
            
            <div className="text-start pt-5 mb-3">
            <span className="text-app"><strong>Podés encontrarnos en:</strong></span>
              <br />
              <span>{props.context && props.context.informacion.direccion}</span>
              <br />
              <span>Horarios: {props.context && props.context.informacion.horario}</span>
              <br />

            </div>
          </div>
          <br />
          <div>{mapa}</div><br/>
        </div>
        
      </div>
      <SeccionAtencion />
      <Footer />
    </div>
  );
}

export default Donde;
